<template>
    <div>
        <ViewTitle title="有效產險清單匯出" />
        <InsuranceListFilter
            v-model:filterLogProp="filterLog"
            v-model:filterPayloadProp="filterPayload"
            @show-data-table="getListData"
        ></InsuranceListFilter>
        <InsuranceListTable
            :listData="listData"
            :isLoading="isTableLoading"
            :eventList="{}"
            :filterLog="filterLog"
            :payload="filterPayload"
        />
    </div>
</template>

<script>
import _ from 'lodash'

import InsuranceListFilter from '@/containers/property/InsuranceListFilter.vue'
import InsuranceListTable from '@/containers/property/InsuranceListTable.vue'
import ViewTitle from '@/components/ViewTitle.vue'

import { getPropertyInsuranceListAPI } from '@/assets/javascripts/api'

export default {
    name: 'PropertyList',
    components: {
        InsuranceListFilter,
        InsuranceListTable,
        ViewTitle
    },
    methods: {
        /**
         * 取得list data
         */
        getListData: async function () {
            if (!this.filterPayload.manCode) return
            try {
                this.isTableLoading = true
                this.$showLoading()
                const params = {
                    ...this.filterPayload,
                    manCode: this.filterPayload.manCode
                }
                const response = await this.$getResponse(
                    params,
                    { apiAlias: 'list', apiName: '此報表' },
                    getPropertyInsuranceListAPI
                )
                this.listData = response.data.insurances
            } catch (error) {
                this.listData = []
            } finally {
                this.isTableLoading = false
                this.$hideLoading()
            }
        }
    },
    computed: {
        tableShowData: function () {
            const handleData = _.cloneDeep(this.listData)
            return handleData
        }
    },
    watch: {},
    data() {
        return {
            filterPayload: {
                manCode: '',
                validDate: ''
            },
            filterLog: {},
            listData: [],
            isTableLoading: false
        }
    }
}
</script>

<style lang="scss" scoped>
:deep(.ins-detail-link) {
    position: relative;
    > .detail-link-icon {
        width: 24px;
        height: 24px;
        cursor: pointer;
    }
}
</style>
